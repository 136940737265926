import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import React from 'react'
import logo from './logo.svg';

function NavBar() {
  return (
      <nav className='nav'>
        <Link to='/experiencia'><img className='logo' src={logo} height={75} width={200}/></Link>
        <ul>
            <CustomLink to='/experiencia'>
            Experência
            </CustomLink>
            <CustomLink to='/artigos'>
            Artigos
            </CustomLink>
            <CustomLink to='/livros'>
            Livros
            </CustomLink>
            <CustomLink to='/contato'>
            Contato
            </CustomLink>
        </ul>
      </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
export default NavBar
