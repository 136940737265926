import drone from './hero-drone.webp'
import CustomizedTimeline from '../components/timeline'

export default function Experiencia() {
    return (
    <div>
    <div  className='page'>
    <div className='s1-page'>Evando Loiola é um renomado líder de projetos industriais de inteligência artificial (IA), além de ser um experiente gerente de projetos de tecnologia e um autor de artigos na comunidade científica. Com uma vasta trajetória, Evando Loiola tem desempenhado um papel fundamental em projetos de desenvolvimento industrial em diversos setores. Sua expertise se estende ao emprego da inteligência artificial em áreas como mineração, engenharia, manutenção e marketing. Suas contribuições têm sido cruciais para impulsionar a eficiência e a inovação em organizações em todo o mundo. </div>
    <div className='s2-page'> <img className='drone' src={drone} width={260} height={260}></img></div>
    </div>
    <CustomizedTimeline/>
    </div>
    )
  }
  
  