import React from 'react'

function CardsLivros() {
    const livros = require('./livros.json');
    return (
    <div className='livros'>
        {livros.map((livro,index) => (
            <div className='card-livro'>
                <li>
                    <div>
                        <div>
                            <a href={livro.link} target="_blank">
                                <div>
                                    <div>
                                        <div>
                                            <img width="400" src={livro.Image} loading="lazy" height="400"/>
                                        </div>
    
                                    </div>
                                </div>
                                <div className="title-livro">
                                    <span dir="ltr">{livro.title}</span>
                                </div>
                                <div className="text" >
                                    <span aria-hidden="true">{livro.short_description}</span>
                                </div>
                                <p class="">by {livro.author}</p>
                            </a>
                        </div>              
                    </div>
                </li>
            </div>
        ))}
    </div>

    );
}

export default CardsLivros
