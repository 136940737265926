import { Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/navbar';
import Livros from './pages/livros';
import Artigos from './pages/artigos';
import Contato from './pages/contato';
import Experiencia from './pages/exeperiencia';

function App() {
  return(
    <>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Experiencia/>} />
        <Route path='/experiencia' element={<Experiencia/>} />
        <Route path='/livros' element={<Livros/>} />
        <Route path='/artigos' element={<Artigos/>} />
        <Route path='/contato' element={<Contato/>} />
      </Routes>
    </>
  )
}

export default App;
