import React from 'react';
import ContactForm from './/../components/contato';

export default function Contato() {
    return (
    
      <div className='contato'>
          <h1>Deixe sua mensagem</h1>
          <ContactForm />
      </div>
    
      )
  }
  
  