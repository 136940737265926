// ContactForm.js
import React, { useState } from 'react';
import emailjs from '@emailjs/browser'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     const templateParams = {
       name: formData.name,
       email: formData.email,
       message: formData.message
     }

    emailjs.send('service_al99g9d','template_k8epihe', templateParams,'lucmQdMjw9tffW-wl')
    .then((response) => {
      console.log('Email enviado', response.status, response.text)
      setFormData({
        name : '',
        email : '',
        message : ''
      })
      alert('Sua mensagem foi enviada com sucesso. Em breve, entrarei em contato. Muito Obrigado!!')
    }, (err) =>  {
      console.log('Erro: ', err)
    }) 
    
  };

  return (
    <form onSubmit={handleSubmit} className='form'>
      <div>
        <label htmlFor="name">Nome:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="email">E-mail:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="message">Mensagem:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div>
      <button type="submit">Enviar</button>
      </div>
    </form>
  );
};

export default ContactForm;
