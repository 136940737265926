import React from 'react'

function Cards() {
    const artigos = require('.//artigos.json');
    return (
    <div className='artigos'>
        {artigos.map((artigo,index) => (
            <div className='card'>
                <li>
                    <div>
                        <div>
                            <a href={artigo.link} target="_blank">
                                <div>
                                    <div>
                                        <div>
                                            <img width="400" src={artigo.Image} loading="lazy" height="400"/>
                                        </div>
    
                                    </div>
                                </div>
                                <div className="title">
                                    <span dir="ltr">{artigo.title}</span>
                                </div>
                                <div className="text" >
                                    <span aria-hidden="true">{artigo.short_description}</span>
                                </div>
                                <p class="">by {artigo.author}</p>
                            </a>
                        </div>              
                    </div>
                </li>
            </div>
        ))}
    </div>

    );
}

export default Cards
